<template>
  <section class="register">
    <Loader :loading="showLoader" />
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-4">
                <div class="brand-logo">
                  <img src="../../assets/images/logo-new.png" />
                </div>
                <h4>Reset Password</h4>
                <!-- <h6 class="font-weight-light pb-3">
                  Signing up is easy. It only takes a few steps
                </h6> -->
                <form @submit.prevent="resetPassword">
                  <div class="form-group">
                    <label for="lastName">Token</label>
                    <input
                      type="text"
                      v-model="user.token"
                      id="token"
                      name="token"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.token.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.user.token.required"
                      class="invalid-feedback"
                    >
                      User Name is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      v-model="user.password"
                      id="password"
                      name="password"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.password.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password.required"
                        >Password is required</span
                      >
                      <span v-if="!$v.user.password.minLength"
                        >Password must be at least 3 characters</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password_confirmation">Confirm Password</label>
                    <input
                      type="password"
                      v-model="user.password_confirmation"
                      id="password_confirmation"
                      name="password_confirmation"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid':
                          submitted && $v.user.password_confirmation.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.password_confirmation.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password_confirmation.required"
                        >Confirm Password is required</span
                      >
                      <span
                        v-else-if="
                          !$v.user.password_confirmation.sameAsPassword
                        "
                        >Passwords must match</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      class="
                        btn btn-block btn-gradient-primary btn-lg
                        font-weight-medium
                        auth-form-btn
                      "
                    >
                      Reset
                    </button>
                    <div class="text-center mt-4 font-weight-light">
                      Already have an account?
                      <router-link to="/auth-pages/login" class="text-primary"
                        >Login</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { required,  minLength, sameAs } from "vuelidate/lib/validators";
import { authApi } from "../../api";
import Loader from '../../components/Loader'
export default {
  name: "register",
  data() {
    return {
      user: {
        token: "",
        password: "",
        password_confirmation: "",
      },
      submitted: false,
      showLoader: false,
    };
  },
  components: {
    Loader
  },
  validations: {
    user: {
      token: { required },
      password: { required, minLength: minLength(3) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    async resetPassword() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.showLoader = true
      const { status, messages } = await authApi.resetPassword({
        ...this.user,
      });
      this.showLoader = false
      if (status == 200) {
          this.$swal({
          type: "success",
          title: "Success",
          text: messages,
        });
      this.$router.push("/auth-pages/login");
        
      } else {
          this.$swal({
          type: "error",
          title: "Oops...",
          text: messages,
        });
        }
      }
    },
};
</script>
